import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../styles/main.css"
import { Helmet } from "react-helmet"



const IndexPage = () => {
    return (

    <main className="serien">
    <Helmet>  
        <title>Martin Zerr – Serien</title>
    </Helmet> 
        <a className="back" href="/">zurück</a>
        <h1>Fünfer Serien</h1>

        <div className="serien_wrapper">
            <div class="serie">
                <Zoom><StaticImage src="../images/Serie_1_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_1_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_1_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_1_06.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_1_07.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
            <div class="serie">
                <Zoom><StaticImage src="../images/Serie_2_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_2_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_2_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_2_06.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_2_07.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
            <div class="serie">
                <Zoom><StaticImage src="../images/Serie_3_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_3_07.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_3_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_3_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_3_06.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
            <div class="serie">
                <Zoom><StaticImage src="../images/_DSC1592_0000_8.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/_DSC1592_0002_6.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/_DSC1592_0003_5.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/_DSC1592_0006_2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/_DSC1592_0008_0.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
            <div class="serie">
                <Zoom><StaticImage src="../images/Serie_4_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_4_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_4_06.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_4_07.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/Serie_4_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>

            <div class="serie">
                <Zoom><StaticImage src="../images/IMG_6123.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/IMG_6129.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/IMG_6161.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/IMG_6082.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/IMG_6120.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
            <div class="serie">
                <Zoom><StaticImage src="../images/img_serie_0000_hz_02.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/img_serie_0001_hz_02.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/BAUM.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/img_serie_0002_IMG_4269-2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
                <Zoom><StaticImage src="../images/img_serie_0003_.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            </div>
        </div>

        <a className="kontakt" href="/kontakt">Kontakt</a>


    </main>

)
}

export default IndexPage